@import "../../Common/colors";
@import "../../Common/const";

.wrapper {
  height: calc(100vh - #{$headerHeight});
  display: flex;
  justify-content: center;
  align-items: center;

  .mainBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 550px;
    background: $white;
    box-shadow: 0 0 8px $shadow;
    border-radius: 16px;
    padding: 32px 16px;
    margin: 16px;

    .logoBox {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 256px;

      font-size: 14px;
      line-height: 140%;
      text-align: center;

      .logo {
        margin-bottom: 40px;
      }
    }

    .description {
      font-family: 'Inter', serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
    }

    .form {
      align-self: stretch;
      background-color: $white;
      padding: 20px;
      border-radius: 16px;

      position: relative;
      display: grid;
      grid-template-columns: 1fr;
      gap: 16px;

      &__submitSucceeded {
        border-radius: 8px;
        padding: 10px;
        border: 1px solid $success;
        color: $success;
        background-color: rgba($success, .1);
        font-family: Inter, serif;
        font-size: 14px;
        line-height: 140%;
        text-align: justify;
      }

      &__submit {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 14px 24px;
        border-radius: 10px;
        border: none;
        cursor: pointer;
        background: $primaryDark;
        color: $white;
      }
    }
  }
}
