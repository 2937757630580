@import "../../Common/colors";

.content {
  padding: 30px;
}

.list {
  display: grid;
  grid-template-columns: repeat(auto-fit, 240px);
  gap: 16px;
}

.portfolio {
  cursor: pointer;

  &:hover {
    box-shadow: 0 8px 16px $shadow;
  }
  &:active {
    opacity: .8;
  }
}

.fab {
  position: fixed;
  bottom: 30px;
  right: 30px;
  background-color: $primaryDark;
  border-radius: 50%;
  cursor: pointer;
  width: 56px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    box-shadow: 0 8px 16px $shadow;
    background-color: rgba($primaryDark, .9);
  }
  &:active {
    background-color: rgba($primaryDark, .5);
  }
}
