@import "../../colors";

.dialog {
  position: relative;
  background: $white;
  border-radius: 16px;
  width: 50vw;
  padding: 24px;

  .closeDialogButton {
    position: absolute;
    right: 16px;
    top: 16px;
    width: 40px;
    height: 40px;
    z-index: 2;

    display: flex;
    align-items: center;
    justify-content: center;

    background: $primaryLight;
    border-radius: 24px;
    border: none;
    cursor: pointer;

    &:hover {
      box-shadow: 0 8px 16px $shadow;
    }
    &:active {
      opacity: .8;
    }
  }
}
