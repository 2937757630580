@import "../../colors";

.page {
  min-width: 100px;
  max-width: 600px;
  background-color: $white;
  color: $black;
  border-radius: 16px;

  &__ratio_box {
    padding: 0 0 86% 0;
    border-radius: 16px;
    position: relative;
    overflow: hidden;
  }

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
  }

  &__media {
    width: 100%;
  }

  &__mediaCounter {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 28px;
    height: 28px;
    background: $primaryLight;
    border-radius: 50%;
    z-index: 2;

    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: $primaryDark;
    font-family: 'Nunito', serif;
    font-weight: 700;
    font-size: 16px;
  }

  &__title_box {
    flex-grow: 1;
    padding: 16px;
    border-top: 1px solid $border;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__title {
    font-family: 'Nunito', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 130%;
    text-align: center;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__description {
    margin-top: 8px;
    font-family: 'Inter', serif;
    font-size: 14px;
    line-height: 140%;
    color: $grey;
    text-align: center;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
