@import "../../colors";

.dialog {
  max-width: 576px;
  width: calc(100vw - 32px);
  margin: 16px;
  padding: 32px;

  &__mainImage {
    width: 100%;
    border-radius: 16px;
    overflow: hidden;
  }

  &__smallImages {
    margin: 32px 0 0 0;
  }

  &__smallImage {
    width: 160px;
    cursor: pointer;
    border-radius: 16px;
    overflow: hidden;
    opacity: .5;
    margin: 0 16px 0 0;

    &.active {
      opacity: 1;
    }
  }

  &__title {
    margin: 32px 0 0 0;
    font-family: 'Nunito', serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
  }

  &__description {
    margin: 32px 0 0 0;
    font-family: 'Inter', serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
  }
}
