@import "../../colors";

.dialog {
  width: 380px;

  font-family: Nunito, serif;
  font-size: 14px;
  line-height: 120%;
  color: $black;

  .header {
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    margin: 0;
  }

  .text {
    margin: 16px 0 0;
    font-family: Inter, serif;
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    margin: 16px 0 0;

    .button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 14px 24px;
      border-radius: 10px;
      border: none;
      cursor: pointer;
    }

    .buttonCancel {
      border: 1px solid $border;
      box-sizing: border-box;
      background: $white;
      color: $primaryDark;
    }

    .buttonConfirm {
      background: $error;
      color: $white;
      margin-left: 16px;
    }
  }
}
