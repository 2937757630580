@import "../../colors";

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  background: $white;
  border: 1px solid $border;
  cursor: pointer;

  &:hover {
    box-shadow: 0 8px 16px $shadow;
  }
  &:active {
    opacity: .5;
  }

  &__icon {
    max-height: 20px;
    max-width: 20px;
  }
}
