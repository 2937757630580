@import "../../colors";

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 24px;
  border-radius: 10px;
  background: $white;
  border: 1px solid $border;
  color: $primaryDark;
  cursor: pointer;

  font-family: 'Nunito', serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;

  &:hover {
    box-shadow: 0 8px 16px $shadow;
  }
  &:active {
    opacity: .5;
  }

  &__icon {
    max-height: 20px;
    max-width: 20px;
  }
}
