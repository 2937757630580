@import "../../colors";
@import "../../const";

.header {
  display: flex;
  justify-content: space-between;
  height: $headerHeight;
  padding: 0 32px;

  .left {
    display: flex;
    overflow: hidden;
  }

  .logo {
    padding: 10px 24px 18px;
    background: $white;
    box-shadow: 0 0 8px $shadow;
    border-radius: 0 0 16px 16px;
    box-sizing: content-box;
  }

  .portfolioTitle {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 24px;
    background: $white;
    box-shadow: 0 0 8px $shadow;
    border-radius: 0 0 16px 16px;
    margin: 0 0 0 24px;
    overflow: hidden;

    & > * {
      align-items: center;
      font-family: 'Nunito', serif;
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  .options {
    display: flex;
    align-items: center;
    padding: 10px 16px;
    background: $white;
    box-shadow: 0 0 8px $shadow;
    border-radius: 0 0 16px 16px;
    & > * + * { margin-left: 16px; } // gap not work in safari
    margin: 0 0 0 24px;

    .languageSelector {
      width: 100px;
      padding: 10px 20px 10px 10px;
      background: $white;
      border: 1px solid $shadow;
      box-sizing: border-box;
      border-radius: 10px;
      cursor: pointer;

      font-family: Nunito, serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 120%;
      color: $primaryDark;

      &:hover {
        box-shadow: 0 8px 16px $shadow;
      }
      &:active {
        opacity: .5;
      }

      appearance: none;
      background-image: url("../../Images/Bottom.svg");
      background-position: right 10px center;
      background-repeat: no-repeat;
    }
  }
}
