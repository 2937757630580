@import "../../colors";

.form {
  background-color: $white;
  padding: 20px;
  border-radius: 16px;

  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;

  &__title {
    display: flex;
    font-family: 'Nunito', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    margin: 0 54px 0 0;
    overflow: hidden;
  }

  &__projectTitle {
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__submit {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 14px 24px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    background: $primaryDark;
    color: $white;
  }

  &__delete_project {
    position: absolute;
    right: 16px;
    top: 16px;
    background-color: $error;
  }
}

.images_title {
  display: flex;
  align-items: center;

  &__text {
    font-family: 'Nunito', serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;

    margin-right: 10px;
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 14px 24px;
    border-radius: 10px;
    cursor: pointer;
    background: $white;
    color: $primaryDark;
    border: 1px solid $primaryDark;
  }
}

.image_box {
  display: flex;

  &__input {
    flex-grow: 1;
    margin-right: 10px;
  }

  &__select_button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background: $white;
    border: 1px solid $border;
    box-sizing: border-box;
    border-radius: 10px;
    cursor: pointer;
    margin-right: 10px;

    &.select {
      border: 2px solid $primaryDark;
    }
  }

  &__button {
    background-color: $error;
  }
}
