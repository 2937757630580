@import "../../Common/colors";

.wrapper {
  padding: 24px 30px;
  margin: auto;
}

.navigation {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.content {
  max-width: 700px;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.add_new_page {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 24px;
  border-radius: 10px;
  cursor: pointer;
  background: $white;
  color: $primaryDark;
  border: 1px solid $primaryDark;
}
