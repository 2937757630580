@import "../../colors";

.media {
  padding: 0 0 61% 0;
  overflow: hidden;
  position: relative;

  & img, & video {
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: absolute;
    top: 0;
    left: 0;
  }
}
