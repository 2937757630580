/* Обнуление */
* {
  padding: 0;
  margin: 0;
  border: 0;
}

*, *:before, *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

:focus, :active {
  outline: none;
}

a:focus, a:active {
  outline: none;
}

nav, footer, header, aside {
  display: block;
}

html, body {
  height: 100%;
  width: 100%;
  line-height: 1;
  font-size: 14px;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  transition-duration: 0.3s;
}

input, button, textarea, select {
  font-family: inherit;
  font-size: inherit;
}

input::-ms-clear {
  display: none;
}

button {
  cursor: pointer;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

a, a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

ul li {
  list-style: none;
}

img {
  vertical-align: top;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: 400;
}

/*--------------------*/

html, body {
  background: url("Common/Images/Papyrus.png"), #FAF5EF;
  background-blend-mode: luminosity, normal;
}

body {
  font-family: Inter, serif;
}

.flicking-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: white;
  opacity: .5;
}
.flicking-pagination-bullet-active {
  opacity: 1;
}
