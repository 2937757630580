@import "../../colors";

.dialog {
  max-width: 648px;
  width: calc(100vw - 32px);
  margin: 16px;
  padding: 32px;

  .header {
    font-family: 'Nunito', serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
  }

  .emptyMessage {
    margin: 24px 0 0;
    font-family: 'Nunito', serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
  }

  .links {
    margin: 24px 0 0;
    display: grid;
    gap: 16px;
    grid-template-columns: 1fr;
  }

  .footer {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin: 24px 0 0;

    .accessSelector {
      width: 275px;
      padding: 10px 20px 10px 10px;
      background: $white;
      border: 1px solid $shadow;
      box-sizing: border-box;
      border-radius: 10px;
      cursor: pointer;

      font-family: Nunito, serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 120%;
      color: $primaryDark;

      &:hover {
        box-shadow: 0 8px 16px $shadow;
      }
      &:active {
        opacity: .5;
      }

      appearance: none;
      background-image: url("../../Images/Bottom.svg");
      background-position: right 10px center;
      background-repeat: no-repeat;
    }
  }
}
