@import "../../colors";

.field {
  display: flex;
  flex-direction: column;

  font-family: 'Nunito', serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;

  &__input {
    background: $white;
    border: 1px solid $border;
    border-radius: 10px;
    padding: 10px 12px;
    height: 41px;

    &_margin {
      margin-top: 4px;
    }
  }

  &__errorMessage {
    display: none;
  }

  &_error {
    color: $error;

    .field__input {
      border: 1px solid $error;
    }

    .field__errorMessage {
      display: block;
      color: $error;
      margin-top: 4px;
    }
  }
}
