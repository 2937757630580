@import "../../colors";

.box {
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr 40px 40px;

  .input {
    min-width: 1px;
    border: 1px solid $border;
    border-radius: 10px;
    padding: 10px 12px;
    height: 41px;
  }

  .delete {
    background-color: $error;
  }
}
