@import "../../colors";

.front_page {
  min-width: 100px;
  max-width: 600px;
  background-color: $white;
  color: $black;
  border-radius: 12px;

  &__ratio_box {
    padding: 0 0 120% 0;
    position: relative;
    overflow: hidden;
    border-radius: 12px;
  }

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
  }

  &__title_box {
    flex-grow: 1;
    padding: 12px;
    border-bottom: 1px solid $border;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__title {
    max-width: 70%;
    text-align: center;
    font-weight: bold;
    line-height: 130%;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__image {
    padding: 0 0 70% 0;
    overflow: hidden;
    position: relative;

    & img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
