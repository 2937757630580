@import "../../colors";

.form {
  background-color: $white;
  padding: 20px;
  border-radius: 16px;

  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;

  &__title {
    font-family: 'Nunito', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
  }

  &__submit {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 14px 24px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    background: $primaryDark;
    color: $white;
  }

  &__delete_project {
    position: absolute;
    right: 16px;
    top: 16px;
    background-color: $error;
  }
}
