[data-reach-dialog-content] {
    display: flex;
    justify-content: center;
    background: none;
    width: max-content;
    padding: 0;
}

[data-reach-dialog-overlay] {
    z-index: 10;
}
