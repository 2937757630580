$primaryLight: #F3F4FC;
$primaryDark: #6B7FE3;

$white: #fff;
$black: #161F29;
$grey: #7C7E8B;
$green: #64B26C;
$greenLight: #F2F7F3;
$orange: #EDAC2E;
$shadow: #F1F1F1;
$border: #DFE1EF;
$borderLink: #CCD3F6;
$placeholder: #C4C4C4;
$topAchieveBorder: #CFA43E;
$medalColor: #755515;
$achieveBackground: #F5ECD6;
$telegramButton: #229ED9;
$googleButton: #EA4436;
$vkButton: #5281B8;

$success: #64B26C;
$successLight: #F2F7F3;
$error: #D35D47;
$errorLight: #FEEEEE;
$warning: #928B74;
$warningLight: #FAF5EF;
