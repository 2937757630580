@import "../../Common/colors";

.wrapper {
  padding: 24px 30px;
  margin: auto;
}

.projects {
  display: grid;
  grid-template-columns: repeat(auto-fit, 326px);
  gap: 24px;
}

.emptyList {
  margin: 54px 0 0 0;
  text-align: center;
  font-family: 'Nunito', serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
}

.project__page {
  cursor: pointer;

  &:hover {
    box-shadow: 0 8px 16px $shadow;
  }
  &:active {
    opacity: .8;
  }
}
