@import "../../Common/colors";

.wrapper {
  padding: 24px 30px;
  margin: auto;
}

.navigation {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
